import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const H1 = makeShortcode("H1");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <H1 mdxType="H1">Translations</H1>
    <p>{`Our statement regarding the content we provide: Fastly's documentation appears in English at docs.fastly.com. Translations to other languages are provided for convenience only. Documentation updates are posted to our `}<a parentName="p" {...{
        "href": "/changes/"
      }}>{`changelog`}</a>{`, which we encourage you to review.`}</p>
    <div className="notranslate show_on_translate">
      <hr />
      <Box backgroundColor="#e6f8fc" border="1px solid #04b2e1" borderRadius="4px" p="3" mb="1" mdxType="Box">
        <p>{`This legal statement provides information about the primary language for Fastly documentation. Fastly's documentation is formally presented in the English language at docs.fastly.com. Translations to other languages are provided for convenience only. Documentation updates are posted in our `}<a parentName="p" {...{
            "href": "/changes/"
          }}>{`changelog`}</a>{`, which we encourage you to review.`}</p>
      </Box>
    </div>
    <p>{`Fastly の公式ドキュメントは docs.fastly.com 上で英語で提供されています。他言語への翻訳は、便宜のためにのみ提供されています。更新、`}<a parentName="p" {...{
        "href": "/changes/"
      }}>{`変更履歴`}</a>{`に随時掲載されます。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      